import React from 'react'
import { FlexboxGrid, Row, Col, Divider } from 'rsuite'
import FooterStyles from './index.styles'
import {Grid} from "@material-ui/core"
import Link from 'next/link'

const openURl=(url) => {
  window.open(url,'blank').focus()
}

const Footer = () => (
  <FooterStyles className='site-footer'>
    <Divider/>
    <div className='app-footer'>
      <Row>
        <Col  xs={24} lg={12} md={12} className='social'>
          <Grid container alignItems="center">
            <img
              loading="lazy"
              src='https://svgl.s3.eu-west-3.amazonaws.com/footer/gmail.png'
              onClick={() =>  openURl('mailto:koydengelsinn@gmail.com')}
            />
            <p>koydengelsinn@gmail.com</p>
          </Grid>
          <Grid container alignItems="center" >
            <img
              loading="lazy"
              src='https://svgl.s3.eu-west-3.amazonaws.com/footer/whatsapp.png'
              onClick={() => openURl('https://wa.me/+905385293025')}
            />
            <p onClick={() => openURl('tel:[+905385293025]')}>+90 538 529 30 25</p>
          </Grid>
          <Grid container alignItems="center">
            <img
              loading="lazy"
              src='https://svgl.s3.eu-west-3.amazonaws.com/footer/placeholder.png'
              onClick={() => openURl('https://goo.gl/maps/mqTBb8p1iYKsPLxw6')}
            />
            <p>Kilimli Mahallesi, Kilimli Küme Evleri, No:5, Narman / Erzurum</p>
          </Grid>
        </Col>
        <Col xs={24} lg={12} md={12} className='social'>
          <Row>
            <Col xs={24}>
              <img
                loading="lazy"
                src='https://svgl.s3.eu-west-3.amazonaws.com/footer/facebook.png'
                onClick={() => openURl('https://www.facebook.com/koydengelsinn/')}
              />
              <img
                loading="lazy"
                src='https://svgl.s3.eu-west-3.amazonaws.com/footer/instagram.png'
                onClick={() =>openURl('https://www.instagram.com/koyden.gelsin/')}
              />
              <img
                loading="lazy"
                src='https://svgl.s3.eu-west-3.amazonaws.com/footer/youtube.png'
                onClick={() => openURl('https://www.youtube.com/channel/UCOrMR9iCHbSr9Ndl2zUClDw')}
              />
            </Col>
            <Col xs={24} className='footer-links'>
              <FlexboxGrid justify={"center"}>
                <ul>
                  <li><Link href='/sozlesme'>Mesafeli Satış Sözleşmesi</Link></li>
                  <li><Link href='/gizlilik'>Gizlilik İlkesi ve Çerez Politikası</Link></li>
                </ul>
              </FlexboxGrid>
            </Col>
          </Row>
        </Col>
      </Row>

      <Grid className='footer-imgs' container alignItems="center">
        <img loading="lazy" src='https://svgl.s3.eu-west-3.amazonaws.com/footer/troy.png'/>
        <img loading="lazy" src='https://svgl.s3.eu-west-3.amazonaws.com/footer/ssl.png'/>
        <img loading="lazy" src='https://svgl.s3.eu-west-3.amazonaws.com/footer/visa-logo.png'/>
        <img loading="lazy" src='https://svgl.s3.eu-west-3.amazonaws.com/footer/mastercard.png'/>
        <img loading="lazy" src='https://svgl.s3.eu-west-3.amazonaws.com/pluxee.png'/>
        <p>Kredi kartı bilgileriniz 256bit SSL sertifikası ile korunmaktadır.</p>
      </Grid>
      <Divider>
        <span>  Köyden Gelsin </span>
        &copy;{(new Date().getFullYear())}
        <span className='p'> Tüm hakları saklıdır</span>
      </Divider>
    </div>

  </FooterStyles>
)

export default Footer
