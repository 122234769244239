import { Alert } from 'rsuite'
import { getSync, getLive, setSync } from '../utils/Storken'
import {createNewOrder, createPayment, getCargoCost, getMinCargoCost, updateOrder} from './CustomerActions'
import { Router } from '../../server/routes'


export const setAdminNewOrder = (setStep, order, isPack, cost, basketObj) => {
  setStep(3)
  createNewOrder({
    order,
    basket:basketObj,
    cost:isPack ? cost : cost + getCargoCost(cost),
    isPack,
    discount: false
  })
}
export const updateAdminOrder = (setStep, order, editingOrder, getcost, isPack, cost, discount, basketObj, setEditingOrder, setEditMode) => {
  setStep(3)
  setEditingOrder({})
  setEditMode(false)
  updateOrder({
    order,
    basketObj,
    orderIDWillUpdate: editingOrder?._id,
    cost: editingOrder?.discount ? getcost()?.toFixed(2) : isPack ? cost : cost + getCargoCost(cost),
    isPack,
    discount: editingOrder?.discount ? discount : false
  })
}
export const customerNewOrder = (paymentObj, order, getcost, isPack, discount, disCost, cupon, basketObj) => createPayment({
  paymentObj,
  order,
  basket:basketObj,
  cost:getcost()?.toFixed(2),
  isPack,
  discount,
  disCost: disCost.toFixed(2),
  cupon
})

export const findIsPack = (setIsPack, discount, disCost) => {
  const packCateogy = getLive('categories')?.find(e => e?.name === 'Paketler')
  const resultBasketPack = getSync('basket')?.filter(e => e?.category === packCateogy?._id)
  if (resultBasketPack.length > 0 || (discount ? disCost : getSync('cost')) >= getMinCargoCost()) setIsPack(true)
  else setIsPack(false)
}
export const changeHash = (props, setStep) => {
  const path = props?.url?.asPath
  const page = '/yenisiparis#'
  const diff = (diffMe, diffBy) => diffMe?.split(diffBy)?.join('')
  diff(diff(path, page)) === 'bilgilerim'
    ? setStep(0)
    : diff(diff(path, page)) === 'adresim'
      ? setStep(1)
      : diff(diff(path, page)) === 'kart'
        ? setStep(2)
        : null
}
export const findAllPackTotalCost = (basket, categories) => {
  const findedPack = basket?.filter(({ category }) => categories?.find(({ _id }) => _id === category)?.name === 'Paketler'
    || categories?.find(({ _id }) => _id === category)?.name === 'Yağlar'
    || categories?.find(({ _id }) => _id === category)?.name === 'Peynirler')
  let total = 0
  findedPack?.map(e => total += e?.cost)
  return total
}
export const userDataControl = (props, order) => {
  const userNul = (Object.keys(order).length === 0)
  const firstStepControl = (order?.name?.length === 0 || order?.phone?.length === 0)
  const secondStepControl = (order?.il?.length === 0 || order?.ilce?.length === 0 || order?.mahalle?.length === 0 || order?.fulladress?.length === 0)
  const path = props.url.asPath
  const page = '/yenisiparis#'
  const diff = (diffMe, diffBy) => diffMe.split(diffBy).join('')
  if (diff(path, page) === 'kart' && (userNul || firstStepControl || secondStepControl)) {
    console.log('BİLGİLER EKSİK', userNul, firstStepControl, secondStepControl)
    Router.pushRoute('/yenisiparis#bilgilerim')
    setSync('step', 0)
  }
}
export const emptyBasketControl = (props, storageBasket) => {
  const path = props?.url.asPath.substr(0, 12)
  if (storageBasket?.length === 0 && path === '/yenisiparis') {
    Router.pushRoute('/')
  }
}
export const tereYagBasketControl = (props, storageBasket) => {
  const path = props?.url.asPath.substr(0, 12)
  const found = storageBasket?.find(e => (
      e?._id === '602040063820ed002b5605c3'
      || e?._id === '6020435e3820ed002b5605dd'
      || e?._id === '602043bb3820ed002b5605df'
      || e?._id === '602043fd3820ed002b5605e1'
      || e?._id === '603638e90c532a002b71ea32'
  ))
  if (!getSync('tereyagAlert') && path === '/yenisiparis' && storageBasket?.length < 2 && found && found?.quantity < 2) {
    Alert.warning('Süt ürünlerinden sipariş vermek için 1 adet daha ürün eklemelisiniz.', 9000)
    Router.pushRoute('/')
    setSync('tereyagAlert', true)
    setTimeout(() => {
      setSync('tereyagAlert', false)
    }, 1000)
  }
}
export const CustomerOrderHepler = {
  setAdminNewOrder,
  updateOrder
}
