import React from 'react'
import { Icon } from 'rsuite'
import {Router} from '../../../../server/routes'
import {setSync} from "../../../utils/Storken";

const links = [
  {
    title:'Yönetim Paneli',
    href:'/dashboard',
    icon:'dashboard'
  },
  {
    title:'Siparişler',
    href:'/siparisler',
    icon:'shopping-basket'
  },
  {
    title:'Ürünler',
    href:'/urunler',
    icon:'shopping-cart'
  },
  {
    title:'Yorumlar',
    href: '/yorumlar',
    icon:'edit'
  },
  {
    title:'Kategoriler',
    href:'/kategoriler',
    icon:'object-group'
  },
  {
    title:'Kuponlar',
    href:'/indirimler',
    icon:'tag'
  },
  {
    title:'Teklifler',
    href:'/teklifler',
    icon:'tag'
  },
  {
    title:'Kargo Yükle',
    href:'/yukleme',
    icon:'tag'
  },
  {
    title:'Müşteriler',
    href:'/musteriler',
    icon:'user-analysis'
  }
]
const AdminMenu = ({path}) => (
  <>
    {
      links.map(e => (
        <div className='eleman'>
          <a className={e.href === path ? 'aktif': ' '} onClick={() =>{
            Router.pushRoute(e.href)
            setSync('onleft', false)
          } }>
            <span><Icon icon={e.icon} /></span>
            {e.title}
          </a>
        </div>
      ))
    }
  </>
)
export default AdminMenu
