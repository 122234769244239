import React, { useEffect } from 'react'
import { Button, Drawer, Dropdown, Icon, IconButton } from 'rsuite'
import Link from 'next/link'
import { useLive, useSync } from '../../../utils/Storken'
import { signOutWithUser } from '../../../services/UserActions'
import AdminLeftMenu from './AdminLeftMenu'


const links = [
  {
    href:'/',
    title:'Ana Sayfa',
    icon:'home'
  },
  {
    href: '/profile',
    title: 'Siparişlerim',
    icon: 'shopping-bag'
  },
  {
    href:'/gizlilik',
    title:'Gizlilik ilkesi',
    icon:'lock'
  },
  {
    href:'/sozlesme',
    title:'Mesafeli satış sözleşmesi',
    icon:'file-text'
  }]

const LeftMenu = ({ url }) => {
  // eslint-disable-next-line react/prop-types
  const { asPath } = url
  const [user, setUser] = useLive('user')
  const [onLeft, setOnLeft] = useSync('onleft')
  useEffect(() => {

  }, [asPath])
  const HeaderDrop = () => (
    <Dropdown title={user.username}>
      <Dropdown.Item onSelect={() => signOutWithUser()}>Çıkış Yap</Dropdown.Item>
    </Dropdown>
  )

  return (
    <Drawer
      placement='left'
      size='xs'
      show={onLeft}
      onHide={() => setOnLeft(false)}
      className='yan_menu'
    >
      <Drawer.Header style={user.username ? { visibility:'hidden', display:'none' } : null} className='yan_menu_baslik'>
        <Drawer.Title className='yan_menu_baslik_ic'>
          {/* eslint-disable-next-line react/button-has-type */}
          <a target='_blank' href='https://wa.me/+905385293025' className='yan_menu_giris_butonu'>
            <span style={{ paddingLeft:'4px', paddingRight:'4px', whiteSpace:'nowrap' }}>
                WhatsApp Destek {' '}
              <Icon icon='whatsapp' />
            </span>
          </a>
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className='yan_menu_liste'>
        {
          user.username
            ? <AdminLeftMenu path={asPath}/>
            : null
        }
        {
          // eslint-disable-next-line no-unused-vars
          links.map(e => (
            <div key={e.href} className='eleman'>
              <a onClick={() => setOnLeft(false)} className={`link ${e.href === asPath ? 'aktif' : ''}`}>
                <span><Icon size='lg' icon={e.icon} /></span>
                <Link href={e.href}>{e.title}</Link>
              </a>
            </div>
          ))
        }
      </Drawer.Body>
      <Drawer.Footer>
        {
          user.username
            ? (
              <IconButton onClick={() => signOutWithUser()} icon={<Icon icon='sign-out' />}>
                Çıkış Yap
              </IconButton>
            )
            : (
              <Link href='/login'>
                <IconButton icon={<Icon icon='sign-in' />} />
              </Link>
            )
        }
      </Drawer.Footer>
    </Drawer>
  )
}
export default LeftMenu
